import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl";

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const H2 = styled.h2`
    font-size: 25px;
    width: 100%;
    margin-top: 10px;
}`;

const Ol = styled.ol`
    list-style-position: inside;
}`;

const UL = styled.ul`
    list-style-type: upper-latin;
    list-style-position: inside;
}`;

const P = styled.p`
    margin-top: 5px;
    font-size: inherit;
}`;

const PrivacyPolicy = ({ data }) =>  ({
 
    render(){
            return(
                <Layout>
                    <SEO title="Privacy Policy" />

                            <section>

                                <div className="site-breadcrumb">
                                    <div className="container">		
                                    </div>
                                </div>

                                <section className="page-section blog-page">
                                <div className="container">
                                    <TextJustified className="row" id="privacy-policy">
                                        <H1><strong>POLITICA DE PRIVACIDAD</strong></H1>
                                        <H2><strong><u>Datos del responsable del tratamiento</u></strong></H2>
                                        <P><strong>Visaverde Consultancy Services S.L.</strong> (en adelante <strong>Visaverde</strong>) con domicilio en Calle Alemania número 11, CC Patio Canario, local número 4, San Eugenio Alto, 38660 Adeje N.I.F. B-38510228, teléfono de contacto +34 922 714 290, correo del coordinador de protección de datos <a href="mailto:pdatos.visaverde@gmail.com">pdatos.visaverde@gmail.com</a></P>
                                        <H2><strong><u>Normativa aplicable:</u></strong></H2>
                                        <P>La política de privacidad se ha diseñado de acuerdo a las prescripciones del Reglamento Europeo de Protección de Datos Personales <strong>RGPD 679/2016 de 27 de abril</strong> y la Ley Orgánica de Protección de Datos Personales y Garantía de Derechos Digitales <strong>LOPDGDD 3/2018 de 5 de diciembre.</strong></P>
                                        <H2><strong><u>Finalidad del tratamiento de los datos personales.</u></strong></H2>
                                        <Ol>
                                        <li><strong>Registro de usuarios finales:</strong> Almacenamiento de propiedades favoritas y filtros de búsqueda favoritos para facilitar su navegación.
                                        </li>
                                        <li><strong>Registro a la Newsletter:</strong> Comunicación comercial sobre propiedades destacadas que puedan resultar de interés al usuario registrado.
                                        </li>
                                        <li><strong>Cumplimiento de obligaciones legales:</strong> Haremos entrega de los datos almacenados por cualquier usuario por requerimiento legal de un juzgado o las autoridades legales con potestad para ello.
                                        </li>
                                        </Ol>
                                        <H2><strong><u>Base jurídica del tratamiento:</u></strong></H2>
                                        <P>La legitimación del tratamiento de los datos personales viene otorgada por el cumplimiento con el artículo 6 del RGPD en el que en su apartado primero dice lo siguiente:</P>
                                        <Ol>
                                        <li>El tratamiento solo será lícito si se cumple al menos una de las siguientes condiciones:</li>
                                        </Ol>
                                        <P><strong>a) el interesado dio su consentimiento para el tratamiento de sus datos personales para uno o varios fines específicos;</strong></P>
                                        <P><strong>b) el tratamiento es necesario para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales;</strong></P>
                                        <P>c) el tratamiento es necesario para el cumplimiento de una obligación legal aplicable al responsable del tratamiento;</P>
                                        <H2><strong><u>Datos recabados y tipo de legitimación.</u></strong></H2>
                                        <P>Los datos recabados serán en unos casos por interés legítimo y en otros, por consentimiento expreso del interesado:</P>
                                        <UL>
                                        <li><strong>Usuarios particulares con perfil:</strong> Usuarios que muestran interés en las propiedades publicadas en el portal Visaverde, registrando para ello sus datos en el mencionado portal. Los datos personales recabados son los mínimos necesarios, así como sus propiedades y búsquedas favoritas.
                                        </li>
                                        <li><strong>Usuarios particulares subscriptores del boletín informativo (</strong><em><strong>newsletter)</strong></em><strong>:</strong> Usuarios suscritos a los boletines informativos (newsletter) que de forma periódica envía Visaverde.
                                        </li>
                                        </UL>
                                        <P>Se realizarán procesos automatizados como la publicación de propiedades destacadas, ya sea dentro del portal o mediante el envío del correspondiente boletín informativo (newsletter).</P>
                                        <P>Todos los usuarios pueden ejercer sus derechos de acceso, rectificación, oposición, portabilidad y olvido.</P>
                                        <H2><strong><u>Destinatarios y cesión de los datos.</u></strong></H2>
                                        <P>El destinatario de los datos es Visaverde dentro de su actividad profesional y/o comercial. No obstante, Visaverde podrá recurrir según necesite a empresas externas para el envío de los mencionados boletines informativos (newsletter) produciéndose en estos casos una cesión de datos a terceros, solo a estos efectos.</P>
                                        <H2><strong><u>Tiempo y criterios de conservación de los datos.</u></strong></H2>
                                        <P>Los datos se conservarán de forma general por tiempo indefinido y en todo caso mientras dure la relación contractual en los que exista la misma. El interesado, en cualquier caso, podrá ejercer su derecho de oposición a la conservación de los mismos.</P>
                                        <H2><strong><u>Medidas de seguridad aplicadas</u></strong></H2>
                                        <P>Los datos se alojarán en un servidor cifrado con usuario y clave al que sólo accederán los propietarios de Visaverde, sus empleados o empresas contratadas para la gestión de la base de datos. Tanto los empleados como empresas externas tienen firmado el correspondiente contrato de confidencialidad y/o encargo de tratamiento.</P>
                                        <H2><strong><u>Ejercicio de Derechos.</u></strong></H2>
                                        <P>Es obligatorio informar a los interesados tienen derecho a solicitar del responsable el acceso a sus datos personales, su rectificación o supresión, limitación de su tratamiento o a oponerse al mismo, así como a la portabilidad de dichos datos.</P>
                                        <P>Derecho a presentar una reclamación ante la autoridad de control (Agencia Española de Protección de Datos <a href="https://www.aepd.es/">https://www.aepd.es</a></P>
                                        <P>Derecho a retirar el consentimiento en cualquier momento. (Debe informarse en el mismo formulario en el que se solicita el consentimiento).</P>
                                    </TextJustified>
                                </div>
                                </section>
                            </section>

                </Layout>
            )
        }

    })

export default injectIntl(PrivacyPolicy)
export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`